
const Bilibli = () =>
    import("@view/digital/index");

const Casebilibilipc = () =>
    import("@view/digital/components/index");

const routes = [
    {
        path: '/',
        redirect: '/caseDigitalpc'
    },
    {
        path: "/caseDigitalpc",
        component: Casebilibilipc,
        meta: {
            title: "苏州探索数字技术有限公司"
        }
    },
    {
        path: "/digital/index",
        component: Bilibli,
        meta: {
            title: "苏州探索数字技术有限公司"
        },
    },
];

export default routes;
